import React from "react";

const Logo = () => (
  <svg className="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 148 33">
    <path
      d="M33.657 27.454c-2.355 0-3.386-1.963-3.386-5.104v-2.699c0-3.14 1.03-5.103 3.386-5.103s3.386 1.963 3.386 5.103v2.7c0 3.14-1.03 5.103-3.386 5.103Zm0 4.858c5.987 0 9.619-4.22 9.619-10.894v-.834c0-6.674-3.632-10.895-9.619-10.895s-9.618 4.22-9.618 10.895v.834c0 6.674 3.631 10.894 9.618 10.894ZM44.545 21.418c0 6.674 3.533 10.894 9.47 10.894 5.742 0 8.785-3.975 8.785-9.373v-.54h-5.741v.442c0 2.7-.786 4.564-3.043 4.564-2.306 0-3.239-1.963-3.239-5.055v-2.699c0-3.14.932-5.103 3.239-5.103 2.257 0 3.043 1.914 3.043 4.613v.392H62.8v-.49c0-5.399-3.042-9.374-8.784-9.374-5.938 0-9.471 4.22-9.471 10.895v.834ZM77.3 22.252c0 2.798-.834 4.859-3.24 4.859-2.257 0-2.845-1.62-2.845-4.073V10.13h-6.233V25.05c0 4.22 1.963 7.262 6.576 7.262 2.846 0 4.858-1.374 5.742-3.091v2.65h6.281V10.13H77.3v12.121ZM93.819 26.424 106.43 6.009V.955H86.36v5.447h12.415L86.212 26.963v4.908h20.513v-5.447H93.819ZM117.274 32.312c5.398 0 8.392-3.14 8.784-8.293h-5.594c-.049 2.208-1.08 3.386-3.141 3.386-2.404 0-3.631-1.718-3.631-5.104h12.514v-1.717c0-6.674-2.945-10.895-9.177-10.895-5.742 0-9.373 4.22-9.373 10.895v.834c0 6.674 3.631 10.894 9.618 10.894Zm-3.582-13.495c0-2.748 1.227-4.22 3.337-4.22 2.159 0 3.288 1.472 3.288 4.22h-6.625ZM134.733 19.651c0-2.699.932-4.809 3.386-4.809 2.257 0 2.846 1.62 2.846 4.122v12.907h6.282V16.952c0-4.27-2.061-7.263-6.674-7.263-2.846 0-4.908 1.374-5.84 3.092v-2.65h-6.281v21.74h6.281V19.65ZM.003 13.212v18.656h9.969c8.587 0 12.454-5.104 12.454-14.575v-1.767c0-8.577-3.179-13.572-10.156-14.439v5.61c2.273.86 3.434 3.058 3.434 7.455v4.516c0 5.986-2.153 7.9-6.331 7.9h-2.74V13.211H.004Z"
      className="logo__lettering"
    />
    <path d="M11.394 12.356H.078L11.394 1.044v11.312Z" className="logo__fold" />
  </svg>
);

export default Logo;
