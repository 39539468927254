import { Link } from "gatsby";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import routes from "../routes";
import "../styles/site.scss";
import Footer from "./Footer";
import Logo from "./Logo";
import MainSiteNav from "./menus/MainSiteNav";

const Layout: React.FC<{ children; wrapperClass?: string }> = ({
  children,
  wrapperClass,
}) => {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const handleHamburgerClick = () => setMobileNavOpen(!mobileNavOpen);
  useEffect(() => {
    if (
      process.env.NODE_ENV == "development" &&
      typeof window.gtag === "undefined"
    ) {
      window.gtag = (command, commandParameters, config) =>
        console.log({ command, commandParameters, config });
    }
  }, []);

  return (
    <div
      className={`layout
        ${wrapperClass ? wrapperClass : ""}
        ${mobileNavOpen ? "nav-open" : ""}
      `}
    >
      <Helmet>
        <script
          type="text/javascript"
          src="https://app.termly.io/embed.min.js"
          data-auto-block="on"
          data-website-uuid="6669049f-e15d-4dac-a507-e134aba12d21"
        ></script>
      </Helmet>
      <div className="site-header">
        <div className="container">
          <Link to={routes.home} className="home-link">
            <Logo />
          </Link>
          <MainSiteNav />
          <button className="hamburger btn" onClick={handleHamburgerClick}>
            <span className="line"></span>
            <span className="line"></span>
            <span className="line"></span>
          </button>
        </div>
      </div>
      <main>{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
