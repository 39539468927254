import { OutboundLink } from "gatsby-plugin-google-gtag";
import React from "react";
import { ReactComponent as InstaSvg } from "../svg/insta-icon.svg";
import { ReactComponent as LinkedInSvg } from "../svg/linked-in-icon.svg";
import { ReactComponent as TwitterSvg } from "../svg/twitter-icon.svg";

const SocialMediaIcons: React.FunctionComponent = () => {
  return (
    <ul className="sm-icons">
      <li>
        <OutboundLink
          href="https://twitter.com/docuzen_co"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="sr-only">DocuZen on Twitter</span>
          <TwitterSvg />
        </OutboundLink>
      </li>
      <li>
        <OutboundLink
          href="https://www.linkedin.com/company/docuzen-pdf/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="sr-only">DocuZen on LinkedIn</span>
          <LinkedInSvg />
        </OutboundLink>
      </li>
      <li>
        <OutboundLink
          href="https://www.instagram.com/docuzen_co/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="sr-only">DocuZen on Instagram</span>
          <InstaSvg />
        </OutboundLink>
      </li>
    </ul>
  );
};

export default SocialMediaIcons;
