import { Link } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import routes from "../../routes";
import SocialMediaIcons from "../SocialMediaIcons";

export const FooterMenuCompany = () => (
  <>
    <h3>Company</h3>
    <ul className="link-list">
      {/* <li>
        <Link to={routes.about}>About</Link>
      </li> */}
      <li>
        <Link to={routes.blog}>Blog</Link>
      </li>
      <li>
        <Link to={routes.newsroom}>Newsroom</Link>
      </li>
    </ul>
    <SocialMediaIcons />
  </>
);

export const FooterMenuResources = () => (
  <>
    <h3>Resources</h3>
    <ul className="link-list">
      {/* Developers */}
      {/* <li>
        <Link to={routes.docs}>Documentation</Link>
      </li> */}
      <li>
        <Link to={routes.apiReference}>API reference</Link>
      </li>
      <li>
        <OutboundLink href={routes.status} rel="noopener noreferrer">
          Status
        </OutboundLink>
      </li>

      {/* Resources */}
      {/* <li>
        <Link to={routes.help}>Help center</Link>
      </li> */}
      <li>
        <Link to={routes.contact}>Contact</Link>
      </li>
      <li>
        <Link to={routes.guides}>Guides</Link>
      </li>
      <li>
        <Link to={routes.privacy}>Privacy policy</Link>
      </li>
      <li>
        <Link to={routes.cookies}>Cookie policy</Link>
      </li>
      <li>
        <Link to={routes.terms}>Terms of use</Link>
      </li>
      {/* <li>
        <Link to={routes.sitemap}>Sitemap</Link>
      </li> */}
    </ul>
  </>
);
