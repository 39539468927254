import { Link } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import routes from "../../routes";
import { ReactComponent as Book } from "../../svg/icon-book.svg";
import { ReactComponent as Play } from "../../svg/icon-play.svg";
import { ReactComponent as SignPost } from "../../svg/icon-signpost.svg";

const MainSiteNav = () => (
  <nav>
    <ul>
      <li>
        <Link to={routes.home} className="btn btn-link">
          Product
        </Link>
      </li>
      <li>
        <Link to={routes.pricing} className="btn btn-link">
          Pricing
        </Link>
      </li>
      <li>
        <span className="btn btn-link">Developers</span>
        <ul>
          <li>
            <Link to={routes.gettingStarted}>
              <Play />
              <span>Getting started</span>
            </Link>
          </li>
          <li>
            <Link to={routes.guides}>
              <SignPost />
              <span>Guides</span>
            </Link>
          </li>
          <li>
            <Link to={routes.apiReference}>
              <Book />
              <span>API reference</span>
            </Link>
          </li>
        </ul>
      </li>
      <li>
        <OutboundLink
          className="btn btn-outline-dark"
          href={process.env.GATSBY_APP_ORIGIN}
        >
          Sign in
        </OutboundLink>
      </li>
      <li>
        <OutboundLink
          href={`${process.env.GATSBY_APP_ORIGIN}?signup=true`}
          className="btn btn-primary"
        >
          Get started free
        </OutboundLink>
      </li>
    </ul>
  </nav>
);

export default MainSiteNav;
