export default {
  home: '/',
  signup: '/signup/',
  blog: '/blog/',
  pricing: '/pricing/',
  guides: '/docs/guides/',
  apiReference: '/docs/api-reference/',
  gettingStarted: '/docs/getting-started/',
  cookies: '/cookies/',
  contact: '/contact',
  privacy: '/privacy/',
  terms: '/terms/',
  newsroom: '/newsroom/',
  createAccount: `${process.env.GATSBY_APP_ORIGIN}?signup=true`,
  signIn: `${process.env.GATSBY_APP_ORIGIN}`,

  status: 'https://docuzen.statuspage.io/',
};
