import { Link } from "gatsby";
import React from "react";
import routes from "../routes";
import Logo from "./Logo";
import { FooterMenuCompany, FooterMenuResources } from "./menus/FooterMenus";

const Footer: React.FunctionComponent = () => {
  return (
    <footer className="site-footer">
      <div className="container">
        <div className="row flex-row-reverse">
          <div className="col-md-3 col-6">
            <FooterMenuCompany />
          </div>

          <div className="col-md-3 col-6">
            <FooterMenuResources />
          </div>
          <div className="col-md-6 mb-3 d-flex flex-column justify-content-between">
            <Link to={routes.home} className="home-link d-block mb-3">
              <Logo />
            </Link>
            <p>&copy; DocuZen</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
